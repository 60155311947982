<template>
  <div>
    <sidebar-right :visible="isOpenCreateForm" title="Создать рекламодателя" @hide="isOpenCreateForm = false">
      <create-advertiser-form v-if="isOpenCreateForm" @success="handleCreate" @error="handleError" />
    </sidebar-right>

    <sidebar-right :visible="!!editAdvertiser" title="Редактировать рекламодателя" @hide="editAdvertiser = null">
      <update-advertiser-form
        v-if="!!editAdvertiser"
        :is-visible="!!editAdvertiser"
        :data="{ user: editAdvertiser }"
        @success="handleUpdate"
        @error="handleError"
      />
    </sidebar-right>

    <b-card no-body>
      <b-row class="my-2 px-2 justify-content-end">
        <b-col sm="4" class="mb-1 mb-md-0 mr-md-3 mr-0">
        <b-input-group>
          <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
          <b-input-group-append is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-append>
        </b-input-group>
        </b-col>
        <b-col class="d-flex justify-content-end" sm="1">
          <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
        </b-col>
      </b-row>
      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <advertisers-table :advertisers="data" :sort-by="sortBy" :sort-desc="sortDesc"  @toggle-active="handleToggleAdvertsierActive" @edit-advertiser="handleEditAdvertiser" @sort-change="handleSortChange"/>
        </table-lazy>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BFormSelect, BInputGroup, BIconSearch } from 'bootstrap-vue'
import AdvertisersTable from '@/components/AdvertisersTable'
import SidebarRight from '@/components/SidebarRight'
import PartnerForm from '@/components/PartnerForm'
import TableLazy from '@/components/TableLazy'
import { withFormData } from '@/hoc/withFormData'
import { usePage } from '@/use/page'
import {
  createAdvertiser,
  updateAdvertiser,
  fetchAdvertisers,
  activateAdvertiser,
  deactivateAdvertiser
} from '@/api/advertiser'
import { getToastificationErrorParams, getToastificationSuccessParams } from '@/helpers/toasts'
import { UPDATE_PARTNER_VALIDATION, CREATE_PARTNER_VALIDATION } from '@/constants/validation'
import {infiniteScroll} from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import {search} from "@/use/search"
import {activateCampaign, deactivateCampaign} from "@/api/campigns"
import {activeToggle} from "@/use/actions/activeToggle"
import {ref} from "@vue/composition-api"

const CreateAdvertiserForm = withFormData(PartnerForm, createAdvertiser, CREATE_PARTNER_VALIDATION)
const UpdateAdvertiserForm = withFormData(PartnerForm, updateAdvertiser, UPDATE_PARTNER_VALIDATION)

export default {
  components: {
    BCard,
    BInputGroup,
    BIconSearch,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    SidebarRight,
    AdvertisersTable,
    CreateAdvertiserForm,
    UpdateAdvertiserForm,
    TableLazy
  },
  data() {
    return {
      search: '',
      activeFilter: null,
      isOpenCreateForm: false,
      editAdvertiser: null,
    }
  },
  methods: {
    refetchTable() {
      this.isOpenCreateForm = false
      this.editAdvertiser = null
      this.searchRef = '';
      this.fetchPageData()
    },
    handleEditAdvertiser(id) {
      this.editAdvertiser = this.data.find((u) => u.id === id)
    },
    handleCreate() {
      this.refetchTable()
      this.$toast(getToastificationSuccessParams('Рекламодатель успешно создан'))
    },
    handleUpdate() {
      this.refetchTable()
      this.$toast(getToastificationSuccessParams('Данные рекламодателя успешно обновлены'))
    },
    handleError(e) {
      this.editAdvertiser = null
      // this.isOpenCreateForm = false
      this.$toast(getToastificationErrorParams(e.message))
    }
  },
  setup() {
    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchAdvertisers)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj } = queries(fetchPageData)
    const { searchRef, startSearch } = search(queryObj)
    const { handleToggleActive } = activeToggle()

    const sortBy = ref(null)
    const sortDesc = ref(null)


    const handleSortChange = (sort) => {
      sortBy.value = sort.sortBy;
      sortDesc.value = sort.sortDesc;
      queryObj.field = sort.sortBy;
      queryObj.order = sort.sortDesc ? 'desc' : 'asc';
    }
    const handleToggleAdvertsierActive = (data) => handleToggleActive(data, deactivateAdvertiser, activateAdvertiser)

    return {
      handleToggleAdvertsierActive,
      isLoading,
      handleSortChange,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll,
      queryObj,
      sortBy,
      sortDesc,
      searchRef,
      startSearch
    }
  }
}
</script>
